<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />
                <h2 class="brand-text text-primary ml-1">ZPM MB</h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img fluid :src="imgUrl" alt="Login V2" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t("login.greeting") }}
                    </b-card-title>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- username -->
                            <b-form-group :label="$t('login.username')" label-for="username">
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('login.username')"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="username"
                                        v-model="username"
                                        :state="errors.length > 0 ? false : null"
                                        name="username"
                                        :placeholder="$t('login.username')"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">{{ $t("login.password") }}</label>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid' : null"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="errors.length > 0 ? false : null"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="rememberMe"
                                    name="checkbox-1"
                                >
                                    {{ $t("login.rememberMe") }}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                @click="validationForm"
                            >
                                {{ $t("login.login") }}
                            </b-button>

                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>

    </div>
</template>

<script>
/* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardTitle,
        BImg,
        BForm,
        BButton
    } from 'bootstrap-vue'
    import { required } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver
        },
        setup() {
            const {
                skin,
                layoutType,
                navbarBackgroundColor,
                layoutTypeOptions
            } = useAppCustomizer()

            return {
                skin,
                layoutType,
                navbarBackgroundColor,
                layoutTypeOptions
            }
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                rememberMe: false,
                username: '',
                password: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (this.$store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            }
        },

        methods: {
            validationForm() {
                this.$refs.loginValidation.validate().then(() => {
                    this.loginJWT()
                })
            },
            async loginJWT() {
                const thisIns = this
                thisIns.$store.state.app.showLoader = true

                const payload = {
                    username: thisIns.username,
                    password: thisIns.password,
                    remember_me: thisIns.rememberMe
                }

                try {
                    const loginResponse = await this.$http.post('/v1/login', payload)
                    thisIns.$store.dispatch('user/login', loginResponse.data)
                    thisIns.$store.dispatch('user/setUserData', loginResponse.data)

                } catch (error) {
                    if (error.response.data.includes('user is not active')) thisIns.$printWarning('Uporabnik ni aktiven!')
                    else thisIns.$printWarning(this.$t('general.password_not_correct'))
                } finally {
                    thisIns.$store.state.app.showLoader = false
                }
            }


        }
    }
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
